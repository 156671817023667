.karmaui-typography-text {
  font-style: normal;
}

/* Colors */

.karmaui-typography-text--default {
  color: var(--color-text-default);
}

.karmaui-typography-text--secondary {
  color: var(--color-text-secondary);
}

.karmaui-typography-text--tertiary {
  color: var(--color-text-tertiary);
}

.karmaui-typography-text--inverted {
  color: var(--color-text-inverted);
}

.karmaui-typography-text--success {
  color: var(--color-text-success);
}

.karmaui-typography-text--info {
  color: var(--color-text-info);
}

.karmaui-typography-text--warning {
  color: var(--color-text-warning);
}

.karmaui-typography-text--error {
  color: var(--color-text-error);
}

.karmaui-typography-text--ai {
  color: var(--color-text-ai);
}

.karmaui-typography-text--inherit {
  color: inherit;
}

/* Variants */

.karmaui-typography-text--1 {
  font-size: 1.125rem; /* 18 px */
  line-height: 1.5rem; /* 24px */
}

.karmaui-typography-text--2 {
  font-size: 1rem; /* 16px */
  line-height: 1.375rem; /* 22px */
}

.karmaui-typography-text--3 {
  font-size: 0.875rem; /* 14px */
  line-height: 1.125rem; /* 18 px */
}

.karmaui-typography-text--4 {
  font-size: 0.75rem; /* 12px */
  line-height: 1.125rem; /* 18 px */
}

/*  Font Weights */
.karmaui-typography-text--light {
  font-weight: 200;
}

.karmaui-typography-text--regular {
  font-weight: 400;
}

.karmaui-typography-text--medium {
  font-weight: 500;
}

.karmaui-typography-text--bold {
  font-weight: 700;
}

/* Branding */
.karmaui-typography-text--brand-font {
  font-family: var(--brand-font);
}
