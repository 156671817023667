.karmaui-typography-heading {
  font-style: normal;
}

/* Colors */

.karmaui-typography-heading--default {
  color: var(--color-text-default);
}

.karmaui-typography-heading--ai {
  color: var(--color-text-ai);
}

.karmaui-typography-heading--brand {
  color: var(--brand-text-on-primary-color);
}

/*
  Text variants
*/

h1.karmaui-typography-heading {
  font-size: 2.375rem; /* 38px */
  line-height: 2.8125rem; /* 45px */
}

h2.karmaui-typography-heading {
  font-size: 1.75rem; /* 28px */
  line-height: 1.875rem; /* 30px */
}

h3.karmaui-typography-heading {
  font-size: 1.5rem; /* 24px */
  line-height: 1.8125rem; /* 29px */
}

h4.karmaui-typography-heading {
  font-size: 1.25rem; /* 20px */
  line-height: 1.5rem; /* 24px */
}

h5.karmaui-typography-heading {
  font-size: 1.1rem; /* 18px */
  line-height: 1.3rem; /* 21px */
}

h6.karmaui-typography-heading {
  font-size: 1rem; /* 16px */
  line-height: 1.3rem; /* 21px */
}

/* 
  Font Weights
*/

.karmaui-typography-heading--regular {
  font-weight: 400;
}

.karmaui-typography-heading--medium {
  font-weight: 500;
}

.karmaui-typography-heading--semi-bold {
  font-weight: 600;
}

.karmaui-typography-heading--bold {
  font-weight: 700;
}

/* Branding */

.karmaui-typography-heading--brand-font {
  font-family: var(--brand-font);
}
