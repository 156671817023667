.karmaui-btn {
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  outline: none;
  user-select: none;
  white-space: nowrap;
}

.karmaui-btn:disabled {
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none;
}

.karmaui-btn--disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.karmaui-btn-w-full {
  width: 100%;
}

/*
  Button sizes
*/

.karmaui-btn--medium {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.75rem 1.25rem;
}

.karmaui-btn--small {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  padding: 0.5rem 0.75rem;
}

/* Button variants */

.karmaui-btn--primary {
  background-color: var(--color-background-inverted);
  border: 1px solid var(--color-border-default);
  color: var(--color-text-inverted);
}

.karmaui-btn--secondary {
  background-color: var(--color-background-default);
  border: 1px solid var(--color-border-default);
  color: var(--color-text-default);
}

.karmaui-btn--tertiary {
  background-color: var(--color-background-default);
  border: 1px solid var(--color-border-inverted);
  color: var(--color-text-default);
}

.karmaui-btn--quaternary {
  background-color: var(--color-background-inline);
  border: 1px solid var(--color-border-inverted);
  color: var(--color-text-default);
}

.karmaui-btn--info {
  background-color: var(--color-background-info);
  border: 1px solid var(--color-border-info);
  color: var(--color-text-inverted);
}

.karmaui-btn--danger {
  background-color: var(--color-background-error);
  border: 1px solid var(--color-border-error);
  color: var(--color-text-inverted);
}

.karmaui-btn--text {
  background-color: transparent;
  color: inherit;
  padding: 0;
}

.karmaui-btn--unstyled {

}

.karmaui-btn--ai {
  background-color: var(--color-background-ai);
  border: 1px solid var(--color-border-ai);
  color: var(--color-text-ai);
}

.karmaui-btn--transparent {
  background-color: transparent;
}

.karmaui-btn--border-default {
  border: 1px solid var(--color-border-default);
}

.karmaui-btn--border-inline {
  border: 1px solid var(--color-border-inline);
}
